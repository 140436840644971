import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export function ExpandCollapseButton({
  onExpand,
  onCollapse,
  expanded,
}: {
  onExpand?: VoidFunction;
  onCollapse?: VoidFunction;
  expanded?: boolean;
}) {
  const handleClick =
    (handler: VoidFunction | undefined) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (handler) {
        e.preventDefault();
        e.stopPropagation();
        handler();
      }
    };

  return expanded ? (
    <IconButton aria-label="expand" onClick={handleClick(onCollapse)}>
      <ExpandMore />
    </IconButton>
  ) : (
    <IconButton aria-label="collapse" onClick={handleClick(onExpand)}>
      <ChevronRight />
    </IconButton>
  );
}
