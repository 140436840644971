import { ApolloError } from '@apollo/client';
import { compact, map } from 'lodash';
import { useAllErrors } from './useErrorNotifications';

export const useMeta = (
  ...args: {
    loading?: boolean;
    working?: boolean;
    done?: boolean;
    error?: Error | ApolloError | string | null;
  }[]
) => {
  const errors = compact(map(args, 'error'));
  useAllErrors(...errors);
  const loading = map(args, 'loading').some(Boolean);

  const working =
    args.every((arg) => arg.done === undefined) ||
    args.some((arg) => (!arg.loading && arg.done === false) || arg.working);

  return { loading, errors, working };
};
