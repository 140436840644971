import { ApolloError } from '@apollo/client';
import equal from 'fast-deep-equal';
import { useNotification } from 'hooks/useNotification';
import { compact } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { hashCode } from 'system';

export const useErrorNotifications = (
  ...errors: (Error | ApolloError | string | null | undefined)[]
) => {
  const { sendNotification } = useNotification();
  const errorsRef = useRef(errors);
  const [errorsHash, setErrorsHash] = useState<number>(() =>
    hashCode(compact(errors.map((e) => e?.toString())))
  );

  useEffect(() => {
    if (!equal(errorsRef.current, errors)) {
      errorsRef.current = errors;
      setErrorsHash(hashCode(compact(errors.map((e) => e?.toString()))));
    }
  }, [errors]);

  useEffect(() => {
    if (!errorsHash) {
      return;
    }

    errorsRef.current.forEach((error) => {
      const message = typeof error === 'string' ? error : error?.message;
      if (message) sendNotification(message, 'error');
    });

    if (errorsRef.current) console.error('API error', errorsRef.current);
  }, [errorsHash]);
};

export const useStringErrorNotification = (): [
  Dispatch<SetStateAction<string | undefined | null>>,
] => {
  const [stringError, setStringError] = useState<string | undefined | null>(undefined);
  useErrorNotifications(stringError);
  return [setStringError];
};

export const useAllErrors = (...errors: Parameters<typeof useErrorNotifications>[0][]) =>
  useErrorNotifications(...errors);
