import { createContext, useContext } from 'react';
import { noOp } from 'system';

export const ThemeContext = createContext<{
  darkMode: boolean;
  toggleDarkMode: () => void;
}>({
  darkMode: false,
  toggleDarkMode: noOp,
});

const useTheme = () => useContext(ThemeContext);
export default useTheme;
