export const emptyArray: [] = [];
export const graphqlNull = null as unknown as undefined;
export const provinces = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NS',
  'NT',
  'NU',
  'ON',
  'PE',
  'QC',
  'SK',
  'YT',
];

export const rentFeeId = '0_rent';
export const incentiveFeeId = '1_incentive';
export const maxISODate = '9999-12-31T23:59:59Z';
export const MAX_API_PAGE_SIZE = 1000;
